import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient, QueryKey } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';

import { queryKeys as notificationQueryKeys } from 'Src/feature/notification/useNotification';
import { ServiceError } from 'Src/network/useError';

// 11/2023 BK
// I can currently think of two types of data our system is holds. Below I will list out their properties and the options I would
// use for holding that data in react query.
// NOTE #1: The two data types assume we have messages coming over websockets to tell us when data is out of date. If for some
//          reason that is not true, the stale time should be addjusted.
// NOTE #2: The default settings below are setup for data type 1, which should be the most common in our system.
//  1. Data that is not prefetched (meaning loading it on demand is fast/acceptable). Here we want the stale time to be infinity
//     and the garbage collection time to be 5 mins (the default in react query already). The websocket message from the server can be handled by
//     simply invalidating the query, which will result in an up-to-date copy being fetched, but only if that data is currently being
//     used.
//  2. Data IS prefetched (meaning loading it is slow). Here we want the stale time to be infinity and the garbage collection time to be infinity.
//     Messages from the backend can either send updated data, which we can merge and store into the query client, or if the payload
//     doesn't have the updated info we can force a refetch. Forcing the refetch (rather than invalidating) is nessasary because if no
//     component is using that data, we need to still get the updated data from the backend.

const persistQueries: QueryKey[] = [notificationQueryKeys.all];
// All this does is retry up to three times if the backend times out. All other types of errors from the backend will be treated as
// errors straight away.
const retryFn = (failureCount: number, error: unknown) => {
  if (error instanceof ServiceError && error.isTimeout && failureCount < 3) return true;
  return false;
};
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: retryFn,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      networkMode: 'always',
    },
    mutations: {
      networkMode: 'always',
    },
  },
});
queryClient.setQueryDefaults(notificationQueryKeys.all, { gcTime: Infinity, enabled: false });
const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
  throttleTime: 0,
});
persistQueryClient({
  queryClient,
  persister: localStoragePersister,
  maxAge: Infinity,
  dehydrateOptions: {
    shouldDehydrateQuery: ({ queryKey }) => persistQueries.findIndex((pqk) => pqk.length === queryKey.length && pqk.every((val, idx) => val === queryKey[idx])) >= 0,
  },
});

export { queryClient };
